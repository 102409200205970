/** @jsx jsx */
import { Box, Container, Grid, jsx } from 'theme-ui'
import { FC } from 'react'
import { graphql } from 'gatsby'
import Layout from '~/components/layout'
import SanitizeHtml from '~/components/sanitize-html'
import Img from 'gatsby-image'
import ProductItem from '~/components/product-item'
import { space } from '~/gatsby-plugin-theme-ui'
import ImageWrapper from '~/components/image-wrapper'
import { ContentFieldsType, FluidImageObjectType, ProductItemComponentType } from '~/@types/models'
import HeadingV2 from '~/componentsV2/heading'

interface Props {
  result: {
    id: string
    frontmatter: {
      title: string
      image: string
      roomImage: FluidImageObjectType
      products: readonly ProductItemComponentType[]
    }
    html: string
    fields: ContentFieldsType
  }
}

export const roomPageQuery = graphql`
  query roomPageQuery($slug: String) {
    result: markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      frontmatter {
        title
        roomImage {
          ...heroImage
        }
        products {
          ...productDetail
          mainImage(type: OVERHEAD) {
            ...shopifyImageSrcSetFull
          }
        }
      }
      html
    }
  }
`

const Room = ({ data }: { data: Props }) => {
  const { title, roomImage, products } = data.result.frontmatter
  const { html: body } = data.result

  return (
    <Layout headerVariant="default" title={title} description={title}>
      <Container
        sx={{
          my: ['l', null, null, '55px'],
        }}
      >
        {roomImage && (
          <ImageWrapper>
            <Img fluid={roomImage.childImageSharp.fluid} alt={title || 'Room'} />
          </ImageWrapper>
        )}
        <HeadingV2
          sx={{
            mt: space.l3,
            mb: space.l2,
            textAlign: 'center',
          }}
        >
          Shop this room
        </HeadingV2>
        <SanitizeHtml content={body} />
        {products && (
          <Grid columns={[1, null, 2]} gap={32}>
            {products.map(item => (
              <Box key={item.id}>
                <ProductItem product={item} visibleVariantsCount={4} />
              </Box>
            ))}
          </Grid>
        )}
      </Container>
    </Layout>
  ) as JSX.Element
}

export default Room
